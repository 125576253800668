import React, { useEffect, useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Navbar from "../Components/Navbar";
import hero_svg from "../assets_2/img/hero-img.svg";
import about_jpg from "../assets_2/img/about.jpg";
import about_2_jpg from "../assets_2/img/about-2.jpg";
import client_1_png from "../assets_2/img/clients/client-1.png";
import client_2_png from "../assets_2/img/clients/client-2.png";
import client_3_png from "../assets_2/img/clients/client-3.png";
import client_4_png from "../assets_2/img/clients/client-4.png";
import client_5_png from "../assets_2/img/clients/client-5.png";
import client_6_png from "../assets_2/img/clients/client-6.png";
import client_7_png from "../assets_2/img/clients/client-7.png";
import client_8_png from "../assets_2/img/clients/client-8.png";
import stats_img from "../assets_2/img/stats-img.svg";
import testimonials_1 from "../assets_2/img/testimonials/testimonials-1.jpg";
import testimonials_2 from "../assets_2/img/testimonials/testimonials-2.jpg";
import testimonials_3 from "../assets_2/img/testimonials/testimonials-3.jpg";
import testimonials_4 from "../assets_2/img/testimonials/testimonials-4.jpg";
import testimonials_5 from "../assets_2/img/testimonials/testimonials-5.jpg";
import app_1 from "../assets_2/img/portfolio/app-1.jpg";
import app_2 from "../assets_2/img/portfolio/app-2.jpg";
import app_3 from "../assets_2/img/portfolio/app-3.jpg";
import product_1 from "../assets_2/img/portfolio/product-1.jpg";
import product_2 from "../assets_2/img/portfolio/product-2.jpg";
import product_3 from "../assets_2/img/portfolio/product-3.jpg";
import branding_1 from "../assets_2/img/portfolio/branding-1.jpg";
import branding_2 from "../assets_2/img/portfolio/branding-2.jpg";
import branding_3 from "../assets_2/img/portfolio/branding-3.jpg";
import books_1 from "../assets_2/img/portfolio/books-1.jpg";
import books_2 from "../assets_2/img/portfolio/books-2.jpg";
import books_3 from "../assets_2/img/portfolio/books-3.jpg";
import team_1 from "../assets_2/img/team/team-1.jpg";
import team_2 from "../assets_2/img/team/team-2.jpg";
import team_3 from "../assets_2/img/team/team-3.jpg";
import team_4 from "../assets_2/img/team/team-4.jpg";
import blog_1 from "../assets_2/img/blog/blog-1.jpg";
import blog_2 from "../assets_2/img/blog/blog-2.jpg";
import blog_3 from "../assets_2/img/blog/blog-3.jpg";
import blog_author from "../assets_2/img/blog/blog-author.jpg";
import blog_author_2 from "../assets_2/img/blog/blog-author-2.jpg";
import blog_author_3 from "../assets_2/img/blog/blog-author-3.jpg";
import header_icon_1 from "../assets/images/app.svg";
import header_icon_2 from "../assets/images/game.svg";
import header_icon_3 from "../assets/images/web.svg";
import header_icon_4 from "../assets/images/blockchain.svg";
import FAQ from "../Components/FAQ";
import RecentBlog from "../Components/RecentBlog";
import Pricing from "../Components/Pricing";
import OurTeam from "../Components/OurTeam";
import Testimonials from "../Components/Testimonials";
export default function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  useLayoutEffect(() => {
    document.querySelector("body").classList.remove("mobile-nav-active");
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    mobileNavShow.classList.remove("d-none");
    mobileNavHide.classList.add("d-none");
    // document.querySelector("body").classList.toggle("mobile-nav-active");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
  }, []);
  return (
    <div>
      <>
        {/* End Top Bar */}

        {/* <Navbar /> */}
        {/* End Header */}
        {/* ======= Hero Section ======= */}
        <section id="hero" className="hero">
          <div className="container position-relative">
            <div className="row gy-5" data-aos="fade-in">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                <h2>
                  Welcome to <span>Invohive.</span>
                </h2>
                <p>
                  Invohive is a software and mobile app development company with
                  a world-class team of talented data scientists, developers,
                  designers, mathematicians, engineers, and creative artists. We
                  are motivated to build elegant and functional digital products
                  that solve complex problems.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#about" className="btn-get-started">
                    Get Started
                  </a>
                  {/* <a
                    href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                    className="glightbox btn-watch-video d-flex align-items-center"
                  >
                    <i className="bi bi-play-circle" />
                    <span>Watch Video</span>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <img
                  src={hero_svg}
                  className="img-fluid"
                  alt=""
                  data-aos="zoom-out"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </div>
          <div className="icon-boxes position-relative">
            <div className="container position-relative">
              <div className="row gy-4 mt-5">
                <div
                  className="col-xl-3 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        width="65px"
                        className="img-fluid"
                        src={header_icon_1}
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        App Development
                      </a>
                    </h4>
                  </div>
                </div>
                {/*End Icon Box */}
                <div
                  className="col-xl-3 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        width="65px"
                        className="img-fluid"
                        src={header_icon_2}
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Game Development
                      </a>
                    </h4>
                  </div>
                </div>
                {/*End Icon Box */}
                <div
                  className="col-xl-3 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        width="65px"
                        className="img-fluid"
                        src={header_icon_3}
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Web Development
                      </a>
                    </h4>
                  </div>
                </div>
                {/*End Icon Box */}
                <div
                  className="col-xl-3 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        width="65px"
                        className="img-fluid"
                        src={header_icon_4}
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Blockchain Development
                      </a>
                    </h4>
                  </div>
                </div>
                {/*End Icon Box */}
              </div>
            </div>
          </div>
        </section>
        {/* End Hero Section */}
        <main id="main">
          {/* ======= About Us Section ======= */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>About Us</h2>
                <p>
                  Invohive is a software and mobile app development company with
                  a world-class team of talented developers, designers,
                  engineers, and creative artists.
                </p>
              </div>
              <div className="row gy-4">
                <div className="col-lg-6">
                  <h3>
                    Invohive brings ideas to life, leveraging modern
                    technologies.
                  </h3>
                  <img
                    src={about_jpg}
                    className="img-fluid rounded-4 mb-4"
                    alt=""
                  />
                  <p>
                    Invohive started its journey in 2008 with a team of seven.
                    Today, it has grown into a full-cycle, mobile-first software
                    development company, with the highest team satisfaction in
                    the industry, independent analyst recognition, and an
                    ever-growing list of delighted clients who work with us
                    again and again.
                  </p>
                  <p>
                    {/* We are proud to have worked with Estee Lauder, Clinique,
                    Engro, Swatch Group, Politico, and OOMCO. */}
                    We have helped dozens of startups with modern technologies.
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="content ps-0 ps-lg-5">
                    <p className="fst-italic">
                      We are proud to have the best team of creative thinkers
                      and fantastic clients from across the world, trusting our
                      developing, designing, and branding.
                    </p>
                    {/* <ul>
                      <li>
                        <i className="bi bi-check-circle-fill" /> Ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill" /> Duis aute
                        irure dolor in reprehenderit in voluptate velit.
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill" /> Ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis
                        aute irure dolor in reprehenderit in voluptate trideta
                        storacalaperda mastiro dolore eu fugiat nulla pariatur.
                      </li>
                    </ul> */}
                    <p>
                      To be the number one choice for businesses when it comes
                      to digital product development, design, quality, and
                      innovation.
                    </p>
                    <p>
                      To help businesses become competitive with the power of
                      digital innovation, outstanding design, and emerging
                      technologies via building intelligent digital products.
                    </p>

                    <div className="position-relative mt-4">
                      <img
                        src={about_2_jpg}
                        className="img-fluid rounded-4"
                        alt=""
                      />
                      <a
                        // href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                        className="glightbox play-btn "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End About Us Section */}
          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients container">
            <div className="container">
              <div className=" ">
                <div className="swiper-wrapper align-items-center">
                  <Carousel infinite responsive={responsive}>
                    <div>
                      <img
                        src={client_1_png}
                        width="80px"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_2_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_3_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_4_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_5_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_6_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_7_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        width="80px"
                        src={client_8_png}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          {/* End Clients Section */}
          {/* ======= Stats Counter Section ======= */}
          <section id="stats-counter" className="stats-counter">
            <div className="container" data-aos="fade-up">
              <div className="row gy-4 align-items-center justify-center">
                <div className="col-lg-6">
                  <img src={stats_img} alt="" className="img-fluid" />
                </div>
                <div className="col-lg-6">
                  {/* <div className="stats-item d-flex align-items-center">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={232}
                      data-purecounter-duration={1}
                      className="purecounter"
                    />
                    <p>
                      <strong>Happy Clients</strong> consequuntur quae diredo
                      para mesta
                    </p>
                  </div> */}
                  {/* End Stats Item */}
                  {/* <div className="stats-item d-flex align-items-center">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={521}
                      data-purecounter-duration={1}
                      className="purecounter"
                    />
                    <p>
                      <strong>Projects</strong> adipisci atque cum quia aut
                    </p>
                  </div> */}
                  {/* End Stats Item */}
                  {/* <div className="stats-item d-flex align-items-center">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={453}
                      data-purecounter-duration={1}
                      className="purecounter"
                    />
                    <p>
                      <strong>Hours Of Support</strong> aut commodi quaerat
                    </p>
                  </div> */}
                  {/* End Stats Item */}
                </div>
              </div>
            </div>
          </section>
          {/* End Stats Counter Section */}
          {/* ======= Call To Action Section ======= */}
          <section id="call-to-action" className="call-to-action">
            <div className="container text-center" data-aos="zoom-out">
              <a
                // href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="glightbox play-btn"
              />
              <h3>Call To Action</h3>
              <p>We make all your dreams come true in a successful project.</p>
              <p>Let's start a project together</p>
              <a className="cta-btn" href="#">
                Call To Action
              </a>
            </div>
          </section>
          {/* End Call To Action Section */}
          {/* ======= Our Services Section ======= */}
          <section id="services" className="services sections-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Our Services</h2>
                <p>
                  We bring to you award-winning products, excellent UI/UX, and a
                  world-class tech talent of web and mobile arena that bridges
                  the gap between the creative and technology world. At
                  Invohive, our solutions become a technology brand that others
                  take inspiration from.
                </p>
              </div>
              <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-4 col-md-6">
                  <div className="service-item  position-relative">
                    <div className="icon">
                      <i className="bi bi-activity" />
                    </div>
                    <h3>Ideation and evaluation</h3>
                    <p>
                      Enterprises are fast adopting technology to improve their
                      productivity, bring efficiency, and remove barriers
                      preventing free and timely flow of information within the
                      enterprise.
                    </p>
                    <a href="#" className="readmore stretched-link">
                      Read more <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
                {/* End Service Item */}
                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-broadcast" />
                    </div>
                    <h3>Product design</h3>
                    <p>
                      Our products undergo vigorous analysis to craft
                      user-centric, result-driven, and industry-specific
                      designs. We design to enhance user engagement and improve
                      user experience, with intriguing concepts.
                    </p>
                    <a href="#" className="readmore stretched-link">
                      Read more <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
                {/* End Service Item */}
                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-easel" />
                    </div>
                    <h3>Development</h3>
                    <p>
                      We leverage modern technologies and innovative minds to
                      develop dynamic software solutions for multiple platforms,
                      helping clients introduce a one-of-a-kind solution to the
                      audience
                    </p>
                    <a href="#" className="readmore stretched-link">
                      Read more <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
                {/* End Service Item */}
                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-bounding-box-circles" />
                    </div>
                    <h3>Testing</h3>
                    <p>
                      We take Quality Assurance very seriously. Each project we
                      take on goes through numerous iterations of testing to
                      ensure smooth performance, user-friendliness, and
                      bulletproof security.
                    </p>
                    <a href="#" className="readmore stretched-link">
                      Read more <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Our Services Section */}
          {/* ======= Testimonials Section ======= */}
          {/* <Testimonials /> */}
          {/* End Testimonials Section */}
          {/* ======= Portfolio Section ======= */}
          <section id="portfolio" className="portfolio sections-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Portfolio</h2>
                <p>
                  We help our clients grow with cutting-edging technologies,
                  extravagant designs, and innovative software solutions.
                </p>
              </div>
              <div
                className="portfolio-isotope"
                data-portfolio-filter="*"
                data-portfolio-layout="masonry"
                data-portfolio-sort="original-order"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div>
                  <ul className="portfolio-flters">
                    <li data-filter="*" className="filter-active">
                      All
                    </li>
                    <li data-filter=".filter-app">App</li>
                    <li data-filter=".filter-product">Product</li>
                    <li data-filter=".filter-branding">Branding</li>
                    <li data-filter=".filter-books">Books</li>
                  </ul>
                  {/* End Portfolio Filters */}
                </div>
                <div className="row gy-4 portfolio-container">
                  <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <a
                        href="../assets_2/img/portfolio/app-1.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={app_1} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Glamic
                          </a>
                        </h4>
                        <p>
                          A platform that offers beauty services and earning
                          opportunities all under one roof.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Portfolio Item */}
                  <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/product-1.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={product_1} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Party shark
                          </a>
                        </h4>
                        <p>
                          The PartyShark app is an ideal party solution built
                          for young partygoers.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Portfolio Item */}
                  <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/branding-1.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={branding_1} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            4par
                          </a>
                        </h4>
                        <p>
                          4Par is an app that serves golf enthusiasts by helping
                          them indoors with real golf.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/books-1.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={books_1} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Books 1
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/app-2.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={app_2} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            App 2
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/product-2.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={product_2} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Product 2
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/branding-2.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={branding_2} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Branding 2
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/books-2.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={books_2} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Books 2
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/app-3.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={app_3} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            App 3
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/product-3.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={product_3} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Product 3
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/branding-3.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={branding_3} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Branding 3
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                  {/* <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                    <div className="portfolio-wrap">
                      <a
                        href="assets/img/portfolio/books-3.jpg"
                        data-gallery="portfolio-gallery-app"
                        className="glightbox"
                      >
                        <img src={books_3} className="img-fluid" alt="" />
                      </a>
                      <div className="portfolio-info">
                        <h4>
                          <a href="portfolio-details.html" title="More Details">
                            Books 3
                          </a>
                        </h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                      </div>
                    </div>
                  </div> */}
                  {/* End Portfolio Item */}
                </div>
                {/* End Portfolio Container */}
              </div>
            </div>
          </section>
          {/* End Portfolio Section */}
          {/* ======= Our Team Section ======= */}
          {/* <OurTeam /> */}
          {/* End Our Team Section */}
          {/* ======= Pricing Section ======= */}
          {/* <Pricing /> */}
          {/* End Pricing Section */}
          {/* ======= Frequently Asked Questions Section ======= */}
          {/* <FAQ /> */}
          {/* End Frequently Asked Questions Section */}
          {/* ======= Recent Blog Posts Section ======= */}
          {/* <RecentBlog /> */}
          {/* End Recent Blog Posts Section */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Contact</h2>
                <p>
                  Our Contact Us page is your direct line to us for any
                  questions, feedback, or support you might need. Reach out, and
                  let's connect!
                </p>
              </div>
              <div className="row gx-lg-0 gy-4">
                <div className="col-lg-4">
                  <div className="info-container d-flex flex-column align-items-center justify-content-center">
                    <div className="info-item d-flex">
                      <i className="bi bi-geo-alt flex-shrink-0" />
                      <div>
                        <h4>Location:</h4>
                        <p>
                          72a Onkaparinga crescent kaleen Post code 2617 State
                          Canberra
                        </p>
                      </div>
                    </div>
                    {/* End Info Item */}
                    <div className="info-item d-flex">
                      <i className="bi bi-envelope flex-shrink-0" />
                      <div>
                        <h4>Email:</h4>
                        <p>Info@Invohive.com</p>
                      </div>
                    </div>
                    {/* End Info Item */}
                    <div className="info-item d-flex">
                      <i className="bi bi-phone flex-shrink-0" />
                      <div>
                        <h4>Call:</h4>
                        <p>+6 1423 3733 50</p>
                      </div>
                    </div>
                    {/* End Info Item */}
                    <div className="info-item d-flex">
                      <i className="bi bi-clock flex-shrink-0" />
                      <div>
                        <h4>Open Hours:</h4>
                        <p>Mon-Sat: 11AM - 23PM</p>
                      </div>
                    </div>
                    {/* End Info Item */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    className="php-email-form"
                  >
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required=""
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows={7}
                        placeholder="Message"
                        required=""
                        defaultValue={""}
                      />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div>
                {/* End Contact Form */}
              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer" className="footer">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="index.html" className="logo d-flex align-items-center">
                  <span>Invohive.</span>
                </a>
                <p>
                  Cras fermentum odio eu feugiat lide par naso tierra. Justo
                  eget nada terra videa magna derita valies darta donna mare
                  fermentum iaculis eu non diam phasellus.
                </p>
                <div className="social-links d-flex mt-4">
                  <a href="#" className="twitter">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="#" className="facebook">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="#" className="instagram">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="#" className="linkedin">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="#">Web Design</a>
                  </li>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Product Management</a>
                  </li>
                  <li>
                    <a href="#">Marketing</a>
                  </li>
                  <li>
                    <a href="#">Graphic Design</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  72a Onkaparinga crescent kaleen, <br /> Post code 2617, <br />{" "}
                  Canberra.
                  <br />
                  <br />
                  <strong>Phone:</strong> +61423373350
                  <br />
                  <strong>Email:</strong> Info@invohive.com
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp; Support@invohive.com
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp; Billing@invohive.com
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span>Invohive.</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/Cubix.-bootstrap-business-website-template/ */}
              {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
            </div>
          </div>
        </footer>
        {/* End Footer */}
        {/* End Footer */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
        {/* <div id="preloader" /> */}
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </>
    </div>
  );
}
