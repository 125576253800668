import logo from "./logo.svg";
import "./App.css";
import "./assets_2/vendor/bootstrap/css/bootstrap.min.css";
import "./assets_2/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets_2/vendor/aos/aos.css";
import "./assets_2/vendor/glightbox/css/glightbox.min.css";
import "./assets_2/vendor/swiper/swiper-bundle.min.css";
import "./assets_2/css/main.css";
import "aos/dist/aos.css";
import "aos/dist/aos.js";
import AOS from "aos";

import Router from "./Routes";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (AOS) {
      AOS.init();
      AOS.refresh();
    }
  }, []);

  return (
    <div className="">
      <Router />
    </div>
  );
}

export default App;
