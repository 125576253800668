import React, { useEffect, useLayoutEffect, useState } from "react";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/Navbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FormData from "../assets/FormData.json";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const responsive4 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function MobileAppDevelopment() {
  const [selectedWebApp, setSelectedWebApp] = useState(0);
  const [selectedTechnology, setSelectedTechnology] = useState(9);
  useLayoutEffect(() => {
    document.querySelector("body").classList.remove("mobile-nav-active");
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    mobileNavShow.classList.remove("d-none");
    mobileNavHide.classList.add("d-none");
    // document.querySelector("body").classList.toggle("mobile-nav-active");
  }, []);
  return (
    <div>
      <div id="content-wrapper">
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".slick-arrow{font-size:20px!important}.slick-prev:before,.slick-next:before{font-family:'slick';font-size:40px}button.slick-next.slick-arrow{margin-left:50px}.testclass .slick-track{padding-top:10px}.slick-slide:focus{outline:0}.slick-arrow{position:absolute;bottom:-2.607em;top:auto;left:46%;display:block;padding:0;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);cursor:pointer;color:none;font-size:2em;border:none;outline:none;background:transparent}.boxhoverseo a h3::first-letter{text-transform:initial!important}@media all and (max-width:991px){.popup-form{width:80%!important;margin:0 auto!important;text-align:center!important}.testclass .slick-track{height:AUTO !IMPORTANT}.font-47{font-size:47px!important}}",
          }}
        />
        <div className="spaceings">
          <section className="vc_row d-flex flex-wrap align-items-center sp-section-top header-section-top-glb bd-section-top">
            <div
              className="liquid-row-overlay background-cover _seobackgroundcvrsp top-form-overlay"
              style={{ backgroundColor: "#1869f4" }}
            />
            <div
              className="container position-relative"
              style={{ zIndex: 999 }}
            >
              {/* <Navbar /> */}
              <div className="row mt-130 mb-90 mt-1200-30 text-center-991 d-flex own-row-reverse div-centers d-flexnone-hun  ">
                <div
                  className="col-md-7 col-sm-7 col-xs-12 ca-initvalues-applied   lqd-animations-done"
                  data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"150","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":45,"translateZ":-109,"rotateX":-71,"opacity":0},"animations":{"translateY":0,"translateZ":0,"rotateX":0,"opacity":1}}'
                >
                  <div
                    className="ld-fancy-heading font-mblh1 lqd-unit-animation-done"
                    style={{
                      transform:
                        "translateY(0px) translateZ(0px) rotateX(0deg)",
                      opacity: 1,
                    }}
                  >
                    <h2
                      className="text-white font-47 font-size-50 mt-xs-5 mt-sm-3 fontownsize-side"
                      title="Full-stack mobile app development company"
                      style={{ textTransform: "initial!important" }}
                    >
                      {" "}
                      Full-Stack Mobile App{" "}
                      <span className="blockdivs">Development Company</span>
                      <span className="blockdivs" />
                    </h2>
                  </div>
                  <div className="ld-fancy-heading text-white">
                    <p
                      className="text-white mb-20 lh-15 font-size-21 split-text-applied"
                      data-fittext="true"
                      data-fittext-options='{"compressor":0.6,"maxFontSize":"currentFontSize","minFontSize":"20"}'
                      data-split-text="true"
                      data-split-options='{"type":"lines"}'
                      style={{ fontSize: 21 }}
                    >
                      <span className="ld-fh-txt" style={{}} />
                    </p>
                    <div
                      className="lqd-lines split-unit lqd-unit-animation-done"
                      style={{
                        display: "block",
                        textAlign: "start",
                        position: "relative",
                      }}
                    >
                      <span
                        data-text="Delivering Sophistication through a Refined Process — "
                        className="split-inner"
                      >
                        <span className="split-txt">
                          Delivering Sophistication through a Refined Process —{" "}
                        </span>
                      </span>
                    </div>
                    <div
                      className="lqd-lines split-unit lqd-unit-animation-done"
                      style={{
                        display: "block",
                        textAlign: "start",
                        position: "relative",
                      }}
                    >
                      <span
                        data-text="We build purpose-driven mobile app Development "
                        className="split-inner"
                      >
                        <span className="split-txt">
                          We build purpose-driven mobile app Development{" "}
                        </span>
                      </span>
                    </div>
                    <div
                      className="lqd-lines split-unit lqd-unit-animation-done"
                      style={{
                        display: "block",
                        textAlign: "start",
                        position: "relative",
                      }}
                    >
                      <span
                        data-text="Services with your success in mind. "
                        className="split-inner"
                      >
                        <span className="split-txt">
                          Services with your success in mind.{" "}
                        </span>
                      </span>
                    </div>
                    <p />
                    <p
                      className="text-white lh-15 font-size-20 dblock-small"
                      style={{ display: "none" }}
                    >
                      <i className="fa fa-check pr-15" aria-hidden="true" />{" "}
                      Award winning Mobile app developers
                    </p>
                    <p
                      className="text-white lh-15 font-size-20 dblock-small"
                      style={{ display: "none" }}
                    >
                      <i className="fa fa-check pr-15" aria-hidden="true" />{" "}
                      World-class Mobile app development
                    </p>
                  </div>
                  <div
                    className="logosseo mt-3 lqd-unit-animation-done"
                    style={{
                      transform:
                        "translateY(0px) translateZ(0px) rotateX(0deg)",
                      opacity: 1,
                    }}
                  >
                    <div className="carousel-container carousel-nav-left carousel-nav-md carousel-dots-style1 carousel-img">
                      <div
                        className="carousel-items row flickity-enabled is-draggable"
                        data-lqd-flickity='{"cellAlign":"left","prevNextButtons":false,"buttonsAppendTo":"self","pageDots":false,"groupCells":false,"wrapAround":true,"autoPlay":3000,"pauseAutoPlayOnHover":false}'
                        tabIndex={0}
                      >
                        <div
                          className="flickity-viewport is-moving"
                          style={{ height: "100.508px", touchAction: "pan-y" }}
                        >
                          <div
                            className="flickity-slider"
                            style={{
                              left: 0,
                              transform: "translate3d(-79.55%, 0px, 0px)",
                            }}
                          >
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "0%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/politico.png"
                                    className="mt-10"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "25%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/estee-lauder.png"
                                    className="mt-10"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "50%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/walmart.png"
                                    className="mt-10"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "75%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/tissot.png"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6 is-selected is-selected-i-1"
                              aria-selected="true"
                              style={{ position: "absolute", left: "100%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/sapient.png"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "125%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/dream-work-animation.png"
                                    className="mt-10"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "150%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/canon.png"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6"
                              aria-selected="false"
                              style={{ position: "absolute", left: "175%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/artizone.png"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div
                              className="lqd-column carousel-item col-md-3 col-sm-3 col-xs-6 is-last"
                              aria-selected="false"
                              style={{ position: "absolute", left: "200%" }}
                            >
                              <div className="carousel-item-inner">
                                <figure className="text-center opacity-08 reset-opacity-onhover">
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/mobile-app-development/logo/likeable-media.png"
                                    className="mt-10"
                                    alt="Client"
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 col-xs-12">
                  <div
                    id="seopage-form"
                    className="hp100 flex flex-v-middle mt-sm-3 mt-xs-5 w-100"
                    style={{ borderRadius: 4 }}
                  >
                    <div className="form-wrap" id="contact_page-container">
                      <h3 className="heading_h3 mt-0 font-size-35 text-white mb-4 text-black">
                        Request for proposal
                      </h3>
                      {/* <div id="seo-form-custom" class="seo-form-custom"></div> */}
                      <div className="form-wapper form-block fdgn2 seo-form-custom hs_custom_form">
                        <form
                          id="header_form"
                          method="POST"
                          noValidate="novalidate"
                          data-hs-cf-bound="true"
                        >
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="form-group required-control">
                                <label className="control-label" htmlFor="">
                                  Name
                                </label>
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="form-group required-control">
                                <label className="control-label" htmlFor="">
                                  Email
                                </label>
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="form-group required-control">
                                <label className="control-label" htmlFor="">
                                  Phone
                                </label>
                                <input
                                  id="phone"
                                  name="phone"
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="form-group required-control">
                                <label className="control-label" htmlFor="">
                                  Message
                                </label>
                                <textarea
                                  id="message_form"
                                  name="message"
                                  className="form-control mb-md-2 message_form_footer"
                                  rows={3}
                                  placeholder="Message"
                                  required=""
                                  defaultValue={""}
                                />
                                <span
                                  id="error_txt"
                                  className="error_txt"
                                  style={{ color: "red" }}
                                >
                                  <span />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <button
                                type="submit"
                                id="submitservtop"
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="sp-bottom-right-bg">
                    <img
                      src="https://www.cubix.co/themes/responsiv-clean/assets/images/c-images/gl-bottom-header.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="vc_row pt-90 pb-80 mobile-center">
            <div className="container">
              <div className="row justify-content-center pb-90">
                {/* <div class="lqd-column col-md-12 col-sm-12 col-xs-12"></div> */}
                <div className="col-md-5 col-sm-5 col-xs-12">
                  <header className="fancy-title mb-0">
                    <h2
                      className="title h2 mb-3 mt-0"
                      style={{ textTransform: "initial!important" }}
                    >
                      Mobile App Development Services
                    </h2>
                  </header>
                </div>
                <div className="col-md-7 col-sm-7 col-xs-12">
                  <p className="font-size-20 lh-16 pl-md-30 mb-0">
                    <span className="blockdivs">
                      {" "}
                      We promise you top-notch service as we have the
                      best-in-class web and mobile app development service team.
                      With a grade of pure professionals, project delivery is
                      always on schedule with strict adherence to coding
                      standards.{" "}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row designradius ourcontenth row-flex-own ouractiveboxs">
                <div className="lqd-column col-md-3 col-sm-6 col-xs-12">
                  <a href="#contact" data-lity="#popup-modal-contact">
                    <div className="activedboxsed zoomed iconbox iconbox_custom text-left iconbox-shadow-hover iconbox-xl iconbox-heading-sm iconbox-filled box-hover border-athens-gray border-radius-3">
                      <div className="contents">
                        <h3 className="font-weight-normal">App Development</h3>
                        <p className="pb-4">
                          Develop visually pleasing apps optimized to swiftly
                          grow your global business revenue.
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={18}
                          viewBox="0 0 25 18"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#BABABA">
                              <g>
                                <g>
                                  <path
                                    d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z"
                                    transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="lqd-column col-md-3 col-sm-6 col-xs-12">
                  <a href="#contact" data-lity="#popup-modal-contact">
                    <div className="zoomed iconbox iconbox_custom text-left iconbox-shadow-hover iconbox-xl iconbox-heading-sm iconbox-filled box-hover border-athens-gray border-radius-3">
                      <div className="contents">
                        <h3 className="font-weight-normal">
                          Blockchain Deelopment
                        </h3>
                        <p className="pb-4">
                          Be it cryptocurrency development or an ICO launch, our
                          blockchain experts create secure decentralized apps.
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={18}
                          viewBox="0 0 25 18"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#BABABA">
                              <g>
                                <g>
                                  <path
                                    d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z"
                                    transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="lqd-column col-md-3 col-sm-6 col-xs-12">
                  <a href="#contact" data-lity="#popup-modal-contact">
                    <div className="zoomed iconbox iconbox_custom text-left iconbox-shadow-hover iconbox-xl iconbox-heading-sm iconbox-filled box-hover border-athens-gray border-radius-3">
                      <div className="contents">
                        <h3 className="font-weight-normal">
                          <span className="blockdivs">
                            <span className="uppers">A</span>ugmented
                          </span>
                          <span className="uppers"> R</span>eality
                        </h3>
                        <p className="pb-4">
                          Let your customers experience the beauty and power of
                          AR, VR and motion capture technologies.
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={18}
                          viewBox="0 0 25 18"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#BABABA">
                              <g>
                                <g>
                                  <path
                                    d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z"
                                    transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="lqd-column col-md-3 col-sm-6 col-xs-12">
                  <a href="#contact" data-lity="#popup-modal-contact">
                    <div className="zoomed iconbox iconbox_custom text-left iconbox-shadow-hover iconbox-xl iconbox-heading-sm iconbox-filled box-hover border-athens-gray border-radius-3">
                      <div className="contents">
                        {/* <h3 class="font-weight-normal"><div class="blockdivs"><span class="uppers">G</span>ame </div><div class="blockdivs"><span class="uppers"> D</span>evelopment</div></h3> */}
                        <h3 className="font-weight-normal">
                          <span className="blockdivs">
                            <span className="uppers">G</span>ame
                          </span>
                          <span className="uppers"> D</span>evelopment
                        </h3>
                        <p className="pb-4">
                          Build spectacular 2D and 3D multiplayer games for
                          mobile devices powered by addictive gameplay.
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={18}
                          viewBox="0 0 25 18"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#BABABA">
                              <g>
                                <g>
                                  <path
                                    d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z"
                                    transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="vc_row navspca scheme-gray-light">
            <div className="container-fluid px-0">
              <div className="row mx-0 d-md-flex flex-wrap align-items-stretch">
                <div className="tabs tabs-nav-active-underlined tabs-nav-lg d-flex div-centers">
                  <div className="lqd-column col-lg-5 col-xs-12 visible-lg px-0 bg-cover">
                    {FormData?.web?.map((d, i) => {
                      return (
                        <div
                          id="oneseo10"
                          role="tabpanel"
                          className={`tabs-pane pl-md-0 ${
                            selectedWebApp == i && "active"
                          } in`}
                        >
                          <img src={d?.image} alt="Content Box" />
                          <figure></figure>
                        </div>
                      );
                    })}
                  </div>
                  <div className="lqd-column col-lg-7 col-xs-12 pl-md-5 pr-md-0 d-md-flex flex-wrap align-items-center">
                    <div className="row w-100">
                      <div className="col-md-11">
                        <h1
                          className="mt-0"
                          style={{ textTransform: "initial!important" }}
                        >
                          Creative Mobile App Development Company
                        </h1>
                        <p>
                          We help a diverse clientele build result-driven mobile
                          apps development services for different industries.
                        </p>
                        <ul className="nav tabs-nav d-flex" role="tablist">
                          {FormData?.web?.map((d, i) => {
                            return (
                              <li
                                key={i}
                                role="presentation"
                                className={`h5 font-size-16 ${
                                  selectedWebApp == i && "active"
                                }`}
                                onClick={() => setSelectedWebApp(i)}
                              >
                                <a>{d?.title}</a>
                              </li>
                            );
                          })}
                        </ul>
                        {FormData?.web?.map((d, i) => {
                          return (
                            <div
                              key={i}
                              id="oneseo10_else"
                              className={`tabs-pane pl-md-0 mt-3  ${
                                selectedWebApp == i && "active"
                              } in`}
                            >
                              <div className="fancy-box-contents border-radius-3">
                                <div className="fancy-box-header">
                                  <h3 className="font-size-24 font-weight-bold">
                                    {d?.title}
                                  </h3>
                                  <p className="mb-0">{d?.description}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="vc_row d-flex flex-wrap align-items-center pt-90 pb-90 mobile-center">
            <div className="container">
              <div className="">
                <h2 className="h2 text-black mb-4 font-size-40 mt-0">
                  <span className="blockdivs">Our featured works</span>
                </h2>
              </div>
            </div>
            <div className="container ourseo-slides">
              <div className="carousel-container carousel-nav-floated carousel-nav-center carousel-nav-middle carousel-nav-md carousel-nav-square carousel-nav-solid carousel-dots-style4">
                <div className="carousel-items row mx-0">
                  <Carousel responsive={responsive}>
                    <div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <figure className="img-smallmbd">
                          <img
                            src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/slideoneseo.png"
                            alt="seo mobile"
                          />
                        </figure>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="ld-fancy-heading">
                          <div
                            className="iconbox text-left iconbox-icon-shadow iconbox-circle iconbox-sm iconbox-color-pippin"
                            data-plugin-options='{"color": "#fd623c"}'
                          >
                            <div className="iconbox-icon-wrap ico-width-home-port m0-mobiles">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/hictic-logo-icn.png"
                                  className="mt-sm-0-own"
                                  alt="HicTic Mobile Application - Solution for Your Marketing Campaign"
                                />
                              </figure>
                            </div>
                          </div>
                          <header className="fancy-title pr-md-2 pb-2 mb-3 mt-2">
                            <h6 className="text-uppercase ltr-sp-2 font-size-12 font-weight-semibold text-secondary">
                              Mobile App
                            </h6>
                            <h2 className="mb-20 heading3 font-size-28 mt-3">
                              Hictic - Revolutionizing Traditional Advertising
                              &amp; Digital Marketing
                            </h2>
                            <p>
                              Launch new adverts and engage with your customers
                              - an all-in-one platform that promotes your brand
                              and builds engagement.
                            </p>
                          </header>
                        </div>
                        <article className="liquid-lp iconbox-round liquid-blog-item liquid-blog-item-grid liquid-blog-scheme-dark mb-4">
                          <footer className="liquid-lp-footer mycustomlink">
                            <a
                              target="_blank"
                              href="https://www.cubix.co/work/hictic"
                              className="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"
                            >
                              <span>
                                <span
                                  className="btn-line btn-line-before"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                                <span
                                  className="btn-txt"
                                  style={{ color: "#0f63f4" }}
                                >
                                  View Case Study
                                </span>
                                <span
                                  className="btn-line btn-line-after"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                              </span>
                            </a>
                            <link
                              href="https://www.cubix.co/combine/f7692b24cb01faa16b01185826758e15-1645603108"
                              rel="stylesheet"
                            />
                          </footer>
                        </article>
                      </div>
                    </div>

                    <div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <figure className="img-smallmbd min-h2image">
                          <img
                            src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/curious-slider-banner.jpg"
                            alt="seo mobile"
                          />
                        </figure>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="ld-fancy-heading">
                          <div
                            className="iconbox text-left iconbox-icon-shadow iconbox-circle iconbox-sm iconbox-color-pippin"
                            data-plugin-options='{"color": "#fd623c"}'
                          >
                            <div className="iconbox-icon-wrap ico-width-home-port m0-mobiles">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/curious-logo-icn.png"
                                  className="mt-sm-0-own"
                                  alt="Curious Mobile Application - Connect Globally"
                                />
                              </figure>
                            </div>
                          </div>
                          <header className="fancy-title pr-md-2 pb-2 mb-3 mt-2">
                            <h6 className="text-uppercase ltr-sp-2 font-size-12 font-weight-semibold text-secondary">
                              Mobile App
                            </h6>
                            <h2 className="mb-20 heading3 font-size-28 mt-3">
                              Curious - a Social Platform to Connect Globally
                              with Complete Anonymity
                            </h2>
                            <p>
                              Get insights into the latest happenings and
                              connect with people of similar interests in your
                              vicinity with complete anonymity.
                            </p>
                          </header>
                        </div>
                        <article className="liquid-lp iconbox-round liquid-blog-item liquid-blog-item-grid liquid-blog-scheme-dark mb-4">
                          <footer className="liquid-lp-footer mycustomlink">
                            <a
                              target="_blank"
                              href="https://www.cubix.co/work/curious"
                              className="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"
                            >
                              <span>
                                <span
                                  className="btn-line btn-line-before"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                                <span
                                  className="btn-txt"
                                  style={{ color: "#0f63f4" }}
                                >
                                  View Case Study
                                </span>
                                <span
                                  className="btn-line btn-line-after"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                              </span>
                            </a>
                            <link
                              href="https://www.cubix.co/combine/f7692b24cb01faa16b01185826758e15-1645603108"
                              rel="stylesheet"
                            />
                          </footer>
                        </article>
                      </div>
                    </div>

                    <div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <figure className="img-smallmbd min-h2image">
                          <img
                            src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/foodly-image-banner.jpg"
                            alt="seo mobile"
                          />
                        </figure>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="ld-fancy-heading">
                          <div
                            className="iconbox text-left iconbox-icon-shadow iconbox-circle iconbox-sm iconbox-color-pippin"
                            data-plugin-options='{"color": "#fd623c"}'
                          >
                            <div className="iconbox-icon-wrap ico-width-home-port m0-mobiles">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/foodly-sec-icn.png"
                                  alt="Foodly Mobile Application"
                                />
                              </figure>
                            </div>
                          </div>
                          <header className="fancy-title pr-md-2 pb-2 mb-3 mt-2">
                            <h6
                              className="text-uppercase ltr-sp-2 font-size-12 font-weight-semibold text-secondary"
                              style={{ display: "block!important" }}
                            >
                              Mobile App
                            </h6>
                            <h2 className="mb-20 heading3 font-size-28 mt-3">
                              Foodly - the Hub of Delicious{" "}
                              <span className="blockdivs">Cuisines</span>
                            </h2>
                            <p>
                              Order breakfast, lunch, or dinner, rate your
                              favorite restaurants and track your order - one
                              platform to curb your hunger.
                            </p>
                          </header>
                        </div>
                        <article className="liquid-lp iconbox-round liquid-blog-item liquid-blog-item-grid liquid-blog-scheme-dark mb-4">
                          <footer className="liquid-lp-footer mycustomlink">
                            <a
                              target="_blank"
                              href="https://www.cubix.co/work/foodly"
                              className="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"
                            >
                              <span>
                                <span
                                  className="btn-line btn-line-before"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                                <span
                                  className="btn-txt"
                                  style={{ color: "#0f63f4" }}
                                >
                                  View Case Study
                                </span>
                                <span
                                  className="btn-line btn-line-after"
                                  style={{ backgroundColor: "#0f63f4" }}
                                />
                              </span>
                            </a>
                            <link
                              href="https://www.cubix.co/combine/f7692b24cb01faa16b01185826758e15-1645603108"
                              rel="stylesheet"
                            />
                          </footer>
                        </article>
                      </div>
                    </div>
                  </Carousel>
                  ;
                </div>
              </div>
            </div>
          </section>
          <section
            className="vc_row pt-90 pb-70"
            style={{ backgroundColor: "#176af4" }}
          >
            <div className="liquid-row-overlay" />
            <div className="container">
              <div className="row justify-content-center d-flex">
                <div className="col-md-8 col-sm-8">
                  <header className="fancy-title text-white mb-4">
                    <h2
                      className="title h1 mb-4 text-white"
                      style={{ textTransform: "initial!important" }}
                    >
                      Work with a Globally-Recognized{" "}
                      <span className="blockdivs">
                        Mobile Application Development Company
                      </span>
                    </h2>
                    <p className="font-size-20 lh-16">
                      {" "}
                      Mobile application development services hold a gigantic
                      chunk in the economy, estimated to be valued over $6
                      billion by 2023. We have been in the frontline of Mobile
                      App Development Company in USA since we began our journey
                      in 2008.{" "}
                    </p>
                    <p className="font-size-20 lh-16">
                      {" "}
                      We are recognized as one of the world’s most desired
                      mobile app development companies. Our mobile app developer
                      service, designers, and strategists are fueled with
                      creativity and passion for app design. This is why our
                      mobile app services are recognized among the best in the
                      industry in USA.{" "}
                    </p>
                  </header>
                </div>
              </div>
            </div>
          </section>
          <section
            className="vc_row pt-90 pb-70"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="container">
              <div className="row justify-content-center d-flex">
                <div className="col-md-8 col-sm-8">
                  <header className="fancy-title text-black mb-4">
                    <h2
                      className="title h1 mb-4 text-black"
                      style={{ textTransform: "initial!important" }}
                    >
                      Mobile App Services the <span className="uppers">C</span>
                      ubix way
                    </h2>
                    <p className="font-size-20 lh-16">
                      {" "}
                      Whether you are a small-scale business, a startup, or an
                      enterprise looking to build a mobile app, our development
                      process remains the same. We transform your ideas into
                      successful mobile apps that become an integral part of
                      everyone’s lives. With our smooth app development process,
                      the apps we build are engaging, robust, user-friendly, and
                      well-crafted.{" "}
                    </p>
                  </header>
                </div>
              </div>
            </div>
          </section>
          <section
            className="vc_row pt-90 pb-70"
            style={{ backgroundColor: "#1869f4" }}
          >
            <div className="container">
              <div className="row justify-content-center d-flex">
                <div className="col-md-8 col-sm-8">
                  <header className="fancy-title text-white mb-4">
                    <h2
                      className="title h1 mb-4 text-white"
                      style={{ textTransform: "initial!important" }}
                    >
                      Apps Developed by <span className="uppers">C</span>ubix{" "}
                      <span className="blockdivs">Rake in Success</span>
                    </h2>
                    <p className="font-size-20 lh-16">
                      {" "}
                      It doesn’t matter if you are a small-scale business, a
                      unicorn startup or an enterprise, our development process
                      stays the same, which has awesome written all over it. We
                      begin by asking the number one question “How can I turn my
                      idea into a successful app”. “An app that becomes an
                      integral part of everyone’s life”. The answer: our app
                      development process. We want our apps to be intuitive,
                      appealing, robust and friendly.{" "}
                    </p>
                    <p className="font-size-20 lh-16">
                      {" "}
                      We aren’t in a rush to escalate your project as we believe
                      in detailed evaluation of a product, through various
                      stages including Planning, sketching, wireframing,
                      prototyping, developing, quality assurance, and launching
                      an MVP. Our goal is to create mobile apps that are bound
                      to rake in popularity, downloads and marvelous word of
                      mouth.{" "}
                    </p>
                    <p className="font-size-20 lh-16 pb-0 mb-0">
                      You can find Invohive’s leading mobile app development team
                      in USA, Florida, Dubai, and Karachi.
                    </p>
                  </header>
                </div>
              </div>
            </div>
          </section>
          <section className="vc_row d-flex flex-wrap align-items-center pt-90 pb-90 mobile-center bd-our-process">
            <div className="container">
              {/* <div><h2 class="font-size-40 mt-0"></h2><p></p></div> */}
              <div className="row justify-content-center pb-90">
                {/* <div class="lqd-column col-md-12 col-sm-12 col-xs-12"></div> */}
                <div className="col-md-5 col-sm-5 col-xs-12">
                  <header className="fancy-title mb-0">
                    <h2
                      className="title h2 mb-3 mt-0"
                      style={{ textTransform: "initial!important" }}
                    >
                      App Development Process
                    </h2>
                  </header>
                </div>
                <div className="col-md-7 col-sm-7 col-xs-12">
                  <p className="font-size-20 lh-16 pl-md-30 mb-0">
                    <span className="blockdivs">
                      {" "}
                      Being among the top mobile app development companies,
                      Invohive guarantees intuitive mobile apps that are
                      performance-oriented and a lifestyle toolkit. We convert
                      untapped ideas into mobile apps!{" "}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row d-flex div-centers d-flexnone-767">
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="box-bullits">
                    <h2 className="font-size-90 text-black mt-0 mb-0">01</h2>
                    <h3
                      className="font-size-23 text-black mt-0 mb-0"
                      style={{ fontWeight: "bolder" }}
                    >
                      {" "}
                      Plan
                    </h3>
                    <hr className="hrsetseo" />
                    <ul>
                      <li>Business analysis</li>
                      <li>Documenting specifications</li>
                      <li>Preparing wireframess</li>
                      <li>Getting client approval</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="box-bullits">
                    <h2 className="font-size-90 text-black mt-0 mb-0">02</h2>
                    <h3
                      className="font-size-23 text-black mt-0 mb-0"
                      style={{ fontWeight: "bolder" }}
                    >
                      {" "}
                      Design
                    </h3>
                    <hr className="hrsetseo" />
                    <ul>
                      <li>Crafting app prototype</li>
                      <li>Making changes</li>
                      <li>Getting client approval</li>
                      <li>Implementing feedback</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="box-bullits bg-nonesdq">
                    <h2 className="font-size-90 text-black mt-0 mb-0">03</h2>
                    <h3
                      className="font-size-23 text-black mt-0 mb-0"
                      style={{ fontWeight: "bolder" }}
                    >
                      Development
                    </h3>
                    <hr className="hrsetseo" />
                    <ul>
                      <li>Development strategy</li>
                      <li>Testing strategy</li>
                      <li>Product development</li>
                      <li>Product release</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="vc_row pt-90 pb-70"
            style={{ backgroundColor: "#1869f4" }}
          >
            <div className="container">
              <div className="row justify-content-center d-flex">
                <div className="col-md-8 col-sm-8">
                  <header className="fancy-title text-white mb-4">
                    <h2
                      className="title h1 mb-4 text-white"
                      style={{ textTransform: "initial!important" }}
                    >
                      World-famous Mobile Application
                      <span className="blockdivs">Development Company</span>
                    </h2>
                    <p className="font-size-20 lh-16">
                      {" "}
                      Mobile apps indeed hold a significant chunk of the market,
                      currently (about 58%), making it important for your
                      business to invest in one. If you have a wide customer
                      base from your e-commerce website, imagine the power of
                      pairing that with a mobile app!{" "}
                    </p>
                    <p className="font-size-20 lh-16">
                      {" "}
                      Invohive is a popular mobile app development company that
                      helps you achieve your goals. We examine your particular
                      need and work to develop your app solution to perfection!
                      And it all starts with a rock-solid plan that not only
                      sets the parameters of your project features, but also
                      minutely defines and strategizes the length and breadth of
                      programming required. This concrete plan is matched
                      against a strict timeline to ensure delivery on time.{" "}
                    </p>
                    <p className="font-size-20 lh-16">
                      {" "}
                      Every customer is important to us, as we believe in taking
                      everyone who approaches us to the next level. So, no
                      matter what kind of app you are desirous of building; we
                      can build anything for you from scratch.{" "}
                    </p>
                  </header>
                </div>
              </div>
            </div>
          </section>
          <section
            className="vc_row d-flex flex-wrap align-items-center pt-90 pb-0 mobile-center"
            style={{ background: "#f8f8f8" }}
          >
            <div className="container ourtabs-imageschange">
              <div className="mb-5">
                <h2 className="mt-0 mb-3">
                  Mobile App Development – Technology Stack
                </h2>
                <p className="mb-3">
                  We create mobile apps with scalable and tested technology
                  stack to deliver apps par excellence.
                </p>
              </div>
              <div className="row tabs tabs-nav-left tabs-nav-lg m0_auto">
                <div className="col-md-5">
                  <div className="mb-3 mb-sm-4 mb-xs-5">
                    <h3 className="mt-0 font-size-24 mb-sm-4 mt-sm-4">
                      Language
                    </h3>
                    <div className="nav tabs-nav d-flex" role="tablist">
                      {FormData?.technologies.map((d, i) => {
                        if (d?.type == "l") {
                          return (
                            <a
                              className="boximageicon-seo zoomed"
                              onClick={() => setSelectedTechnology(i)}
                            >
                              <img src={d?.image} alt={d?.title} />
                            </a>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="mb-3 mb-sm-4 mb-xs-5">
                    <h3 className="mt-0 font-size-24 mb-sm-4 mt-sm-4">
                      Database
                    </h3>
                    <div className="nav tabs-nav d-flex" role="tablist">
                      {FormData?.technologies.map((d, i) => {
                        if (d?.type == "db") {
                          return (
                            <a
                              className="boximageicon-seo zoomed"
                              onClick={() => setSelectedTechnology(i)}
                            >
                              <img src={d?.image} alt={d?.title} />
                            </a>
                          );
                        }
                      })}
                    </div>
                  </div>

                  <div className="mb-3 mb-sm-4 mb-xs-5">
                    <h3 className="mt-0 font-size-24 mb-sm-4 mt-sm-4">
                      Framework
                    </h3>
                    <div className="nav tabs-nav d-flex" role="tablist">
                      {FormData?.technologies.map((d, i) => {
                        if (d?.type == "fw") {
                          return (
                            <a
                              className="boximageicon-seo zoomed"
                              onClick={() => setSelectedTechnology(i)}
                            >
                              <img src={d?.image} alt={d?.title} />
                            </a>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="tabs-content mt-sm-7">
                    <div
                      style={{ backgroundColor: "#fff", borderRadius: 4 }}
                      className="backgroundwhite-tabsd bgafters bgbefores p-50"
                    >
                      <div id="java01" className="tabs-pane active in">
                        <div className="boxseo-data">
                          <img
                            src={
                              FormData?.technologies[selectedTechnology]?.image
                            }
                            alt="Java for Mobile Application Development"
                          />
                          <h3 className="font-size-24 mt-0">
                            {FormData?.technologies[selectedTechnology]?.title}
                          </h3>
                          <p className="mt-4">
                            {
                              FormData?.technologies[selectedTechnology]
                                ?.description
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          className="vc_row pt-90 pb-90"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <div>
              <h2 className="font-size-40 text-black mt-0">
                Mobile App Development Insights
              </h2>
              <p className="mb-5">
                <span className="blockdivs">
                  Stay in touch with the latest industry trends, market
                  insights, and secret recipes for mobile app developers to
                  build enterprise-level apps.
                </span>
              </p>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <a
                  target="_blank"
                  href="https://www.cubix.co/android-app-development"
                  className="box-blogsseo mt-4"
                >
                  <figure>
                    <img
                      src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/seo-blog-22.jpg"
                      alt="Mobile Game App Development Blog"
                    />
                  </figure>
                </a>
                <div className="boxhoverseo zoomed fromLefts liquid-lp">
                  <a
                    target="_blank"
                    href="https://www.cubix.co/android-app-development"
                    className="box-blogsseo mt-4"
                  >
                    <h3 className="font-size-22 mt-0">
                      Android App Development
                    </h3>
                  </a>
                  <footer
                    className="liquid-lp-footer mycustomlink"
                    style={{ display: "inline" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.cubix.co/android-app-development"
                      className="box-blogsseo mt-4"
                    />
                    <a
                      href="https://www.cubix.co/android-app-development"
                      target="_blank"
                      className="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"
                    >
                      <span>
                        <span
                          className="btn-line btn-line-before"
                          style={{ backgroundColor: "#0f63f4" }}
                        />
                        <span className="btn-txt" style={{ color: "#0f63f4" }}>
                          Learn more
                        </span>
                        <span
                          className="btn-line btn-line-after"
                          style={{ backgroundColor: "#0f63f4" }}
                        />
                      </span>
                    </a>
                    <link
                      href="https://www.cubix.co/combine/f7692b24cb01faa16b01185826758e15-1645603108"
                      rel="stylesheet"
                    />
                  </footer>
                  <span className="rrright-text">5 Min Read</span>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <a
                  href="https://www.cubix.co/iphone-app-development"
                  target="_blank"
                  className="box-blogsseo mt-4"
                >
                  <figure>
                    <img
                      src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/seo-blog-11.jpg"
                      alt="Iphone App Development Blog"
                    />
                  </figure>
                </a>
                <div className="boxhoverseo zoomed fromLefts liquid-lp">
                  <a
                    href="https://www.cubix.co/iphone-app-development"
                    target="_blank"
                    className="box-blogsseo mt-4"
                  >
                    <h3 className="font-size-22 mt-0">
                      i
                      <span style={{ textTransform: "initial!important" }}>
                        P
                      </span>
                      hone App development
                    </h3>
                  </a>
                  <footer
                    className="liquid-lp-footer mycustomlink"
                    style={{ display: "inline" }}
                  >
                    <a
                      href="https://www.cubix.co/iphone-app-development"
                      target="_blank"
                      className="box-blogsseo mt-4"
                    />
                    <a
                      href="https://www.cubix.co/iphone-app-development"
                      target="_blank"
                      className="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"
                    >
                      <span>
                        <span
                          className="btn-line btn-line-before"
                          style={{ backgroundColor: "#0f63f4" }}
                        />
                        <span className="btn-txt" style={{ color: "#0f63f4" }}>
                          Learn more
                        </span>
                        <span
                          className="btn-line btn-line-after"
                          style={{ backgroundColor: "#0f63f4" }}
                        />
                      </span>
                    </a>
                    <link
                      href="https://www.cubix.co/combine/f7692b24cb01faa16b01185826758e15-1645603108"
                      rel="stylesheet"
                    />
                  </footer>
                  <span className="rrright-text">5 Min Read</span>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="ourboxblog-side zoomed">
                  <figure>
                    <img
                      src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/sideboxicon.png"
                      alt="Invohive Insight Icon"
                    />
                  </figure>
                  <h3 className="font-size-28 mt-0 text-white text-center">
                    <span className="blockdivs">Get our best content</span>
                    <span className="blockdivs">straight to your</span> inbox.
                  </h3>
                  <a
                    style={{
                      borderRadius: 12,
                      padding: "0!important",
                    }}
                    href="#contact"
                    className="btn btn-solid bg-white text-uppercase btn-sm round border-thin font-size-14 text-uppercase ltr-sp-15 px-3 lh-175 py-1 mb-2"
                  >
                    <span>
                      <span className="btn-txt" style={{ color: "#1869f4" }}>
                        Contact Us
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="vc_row d-flex flex-wrap align-items-center pt-90 pb-90 mobile-center web-dev-key-high"
          style={{ backgroundColor: "#f4f6f9" }}
        >
          <div className="container">
            <div className="row justify-content-center pb-90">
              {/* <div class="lqd-column col-md-12 col-sm-12 col-xs-12"></div> */}
              <div className="col-md-5 col-sm-5 col-xs-12">
                <header className="fancy-title mb-0">
                  <h2 className="title h2 mb-3 mt-0">
                    <span className="text-capitalize">Key Highlights</span>
                    <span className="blockdivs font-size-30">
                      Our work in numbers.
                    </span>
                  </h2>
                </header>
              </div>
              <div className="col-md-7 col-sm-7 col-xs-12">
                <p className="font-size-20 lh-16 pl-md-30 mb-0">
                  <span className="blockdivs">
                    {" "}
                    Build your web presence to thrive just like many others
                    have, and let experienced technicians and digital creators
                    develop functionalities that rivet your target audience.{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex flex-wrap align-items-center">
              <div className="lqd-column col-md-3 _col-md-custom-col col-xs-6 text-center">
                <div className="key-col liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
                  <div
                    className="font-size-40 liquid-counter-element "
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber":"12+","blurEffect":true}'
                  >
                    <div className="liquid-counter-element liquid-counter-element-hover">
                      <span>12+</span>
                    </div>
                    {/* /.liquid-counter-element */}
                    <span className="text-dark">
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">1</span>
                        <div className="liquid-animator-numbers" data-value={1}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">2</span>
                        <div className="liquid-animator-numbers" data-value={2}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      +
                    </span>
                  </div>
                  {/* /.liquid-counter-elmeent */}
                  <span className="liquid-counter-text liquid-text-bottom text-dark font-size-20">
                    Years
                  </span>
                </div>
                {/* /.liquid-counter */}
              </div>
              {/* /.lqd-column px-2 */}
              <div className="lqd-column col-md-3 _col-md-custom-col col-xs-6 text-center">
                <div className="key-col liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
                  <div
                    className="font-size-40 liquid-counter-element "
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber":"200+","blurEffect":true}'
                  >
                    <div className="liquid-counter-element liquid-counter-element-hover">
                      <span>200+</span>
                    </div>
                    {/* /.liquid-counter-element */}
                    <span className="text-dark">
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">2</span>
                        <div className="liquid-animator-numbers" data-value={2}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">0</span>
                        <div className="liquid-animator-numbers" data-value={0}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">0</span>
                        <div className="liquid-animator-numbers" data-value={0}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      +
                    </span>
                  </div>
                  {/* /.liquid-counter-elmeent */}
                  <span className="liquid-counter-text liquid-text-bottom text-dark font-size-20">
                    Employees
                  </span>
                </div>
                {/* /.liquid-counter */}
              </div>
              {/* /.lqd-column col-md-3 col-xs-6 */}
              <div className="lqd-column col-md-3 _col-md-custom-col col-xs-6 text-center">
                <div className="key-col liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
                  <div
                    className="font-size-40 liquid-counter-element "
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber":"600+","blurEffect":true}'
                  >
                    <div className="liquid-counter-element liquid-counter-element-hover">
                      <span>600+</span>
                    </div>
                    {/* /.liquid-counter-element */}
                    <span className="text-dark">
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">6</span>
                        <div className="liquid-animator-numbers" data-value={6}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">0</span>
                        <div className="liquid-animator-numbers" data-value={0}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">0</span>
                        <div className="liquid-animator-numbers" data-value={0}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      +
                    </span>
                  </div>
                  {/* /.liquid-counter-elmeent */}
                  <span className="liquid-counter-text liquid-text-bottom text-dark font-size-20">
                    Projects
                  </span>
                </div>
                {/* /.liquid-counter */}
              </div>
              {/* /.lqd-column col-md-3 col-xs-6 */}
              <div className="lqd-column col-md-3 _col-md-custom-col col-xs-6 text-center">
                <div className="key-col liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
                  <div
                    className="font-size-40 liquid-counter-element "
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber":"550+","blurEffect":true}'
                  >
                    <div className="liquid-counter-element liquid-counter-element-hover">
                      <span>550+</span>
                    </div>
                    {/* /.liquid-counter-element */}
                    <span className="text-dark">
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">5</span>
                        <div className="liquid-animator-numbers" data-value={5}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">5</span>
                        <div className="liquid-animator-numbers" data-value={5}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      <span className="liquid-counter-animator">
                        <span className="liquid-animator-value">0</span>
                        <div className="liquid-animator-numbers" data-value={0}>
                          <ul>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                          </ul>
                        </div>
                      </span>
                      +
                    </span>
                  </div>
                  {/* /.liquid-counter-elmeent */}
                  <span className="liquid-counter-text liquid-text-bottom text-dark font-size-20">
                    Clients
                  </span>
                </div>
                {/* /.liquid-counter */}
              </div>
              {/* /.lqd-column col-md-3 col-xs-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        <section
          className="vc_row pt-60 pb-100 pt-sm-90-own pb-sm-8-own"
          id="testimonials"
          style={{ background: "#fff!important" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-10">
                <h2 className="text-center mb-5 mt-0 pt-50 pt-sm-0-own">
                  {" "}
                  We develop enterprise-grade software solutions for businesses.{" "}
                </h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="lqd-column col-md-10 col-md-offset-1 mb-40">
                  <div className="lqd-column-inner">
                    <div className="carousel-container carousel-nav-floated carousel-nav-center carousel-nav-middle carousel-nav-md carousel-dots-style1">
                      <div
                        className="carousel-items row "
                        data-lqd-flickity='{"cellAlign":"center","prevNextButtons":true,"buttonsAppendTo":"self","pageDots":false,"groupCells":true,"pauseAutoPlayOnHover":false,"navArrow":"1","navOffsets":{"nav":{"top":"42%"}}}'
                      >
                        <Carousel responsive={responsive}>
                          <div className="carousel-item col-xs-12 text-center px-md-7">
                            <div className="testimonial-content font-size-23">
                              {" "}
                              “The team stood out because of their swift
                              response time and patience. Invohive provided one
                              contact person, which helped streamline project
                              management and coordination. Not only did Invohive
                              follow the budget and timeline, the team even
                              offered additional services at no extra charge.”{" "}
                            </div>
                            <h5 className="mb-0 font-size-22">
                              <span className="uppers">K</span>aren{" "}
                              <span className="uppers">A</span>gresti,{" "}
                              <span className="uppers">f</span>ounder
                            </h5>
                            <p className="mb-0">Nomidate</p>
                          </div>

                          <div className="carousel-item col-xs-12 text-center px-md-7">
                            <div className="testimonial-content font-size-23">
                              {" "}
                              “Willing to accommodate nonprofit budgets, Invohive
                              brought their robust experience to the project.
                              They checked in consistently, and were
                              communicative, easy to reach, and responsive. The
                              Android app shows over 500 downloads to date.”{" "}
                            </div>
                            <h5 className="mb-0 font-size-22">
                              Melissa <span className="uppers">S</span>teward,{" "}
                              <span className="uppers">VP</span> of{" "}
                              <span className="uppers">M</span>arketing
                            </h5>
                            <p className="mb-0">
                              National <span className="uppers">F</span>
                              atherhood <span className="uppers">I</span>
                              nitiative
                            </p>
                          </div>
                          <div className="carousel-item col-xs-12 text-center px-md-7">
                            <div className="testimonial-content font-size-23">
                              {" "}
                              “The app sparked interest in the market and
                              attracted initial users, while receiving no
                              complaints about its functionality or usability.
                              Invohive excelled at addressing user experience
                              concerns, communicating clearly, and investing in
                              the project's potential.”{" "}
                            </div>
                            <h5 className="mb-0 font-size-22">
                              John <span className="uppers">Y</span>ates,{" "}
                              <span className="uppers">F</span>ounder
                            </h5>
                            <p className="mb-0">
                              Pee <span className="uppers">S</span>pots
                            </p>
                          </div>

                          <div className="carousel-item col-xs-12 text-center px-md-7">
                            <div className="testimonial-content font-size-23">
                              {" "}
                              “Thank you again for the successful launch of the
                              3M and Converse applications. Your dedication,
                              hard-work, flexibility, patience and
                              professionalism is recognized and appreciated. I
                              would recommend Social Invohive to any company
                              wishing to create their application!”{" "}
                            </div>
                            <h5 className="mb-0 font-size-22">
                              <span className="uppers">M</span>ichael{" "}
                              <span className="uppers">G</span>uzman,{" "}
                              <span className="uppers">D</span>irector
                            </h5>
                            <p className="mb-0">Sapient</p>
                          </div>

                          <div>Item 1</div>
                          <div>Item 2</div>
                          <div>Item 3</div>
                          <div>Item 4</div>
                        </Carousel>
                        ;{/* /.carousel-item col-xs-12 */}
                        {/* /.carousel-item col-xs-12 */}
                        {/* /.carousel-item col-xs-12 */}
                        {/* /.carousel-item col-xs-12 */}
                      </div>
                      {/* /.carousel-items row */}
                    </div>
                    {/* /.carousel-container */}
                  </div>
                  {/* /.lqd-column-inner */}
                </div>
                {/* /.lqd-column col-md-12 */}
                <div className="lqd-column col-md-12 text-center d-flex justify-content-center clutch-rating">
                  <div className="col pr-3">
                    <a
                      href="https://clutch.co/profile/cubix"
                      target="_blank"
                      className="d-flex align-items-center"
                    >
                      <span className="mr-2">
                        <img
                          src="https://www.cubix.co/storage/app/media/home/clutch-icon.svg"
                          className="clutch-icon"
                          width="30px"
                          alt="Clutch Icon"
                        />
                      </span>
                      <span className="btn-underlined">
                        {" "}
                        4.8 Rating on Clutch{" "}
                      </span>
                    </a>
                  </div>
                  <div className="col pl-3 d-flex align-items-center">
                    <span>Top app developers </span>
                    {/* in New York */}
                  </div>
                </div>
                {/* /.col-md-12 text-center */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
        </section>
        {/* Awards & Recognitions Section */}
        <section style={{ backgroundColor: "#f8f8f8" }}>
          <section
            className="vc_row pt-90 pb-70 _pb-sm-5-own _pt-sm-5-own"
            id="awards-recognitions"
          >
            <div className="container">
              <div className="row">
                <div className="lqd-column col-md-12 mb-4">
                  <header className="fancy-title text-center">
                    <h2 style={{ textTransform: "initial!important" }}>
                      Awards &amp; Recognitions
                    </h2>
                  </header>
                </div>
                {/* /.lqd-column col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
            <div className="container-fluid">
              <div className="row">
                <div className="lqd-column col-md-12">
                  <div
                    className="carousel-container _carousel-nav-left _carousel-nav-xl _carousel-nav-bordered carousel-nav-circle carousel-dots-style1 carousel-nav-border-transparent carousel-nav-hover-gray"
                    id="casestudy-wrap"
                  >
                    <div className="carousel-items row">
                      <Carousel responsive={responsive4}>
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Android App Development Companies"
                            className="div-c-box"
                            href="https://www.goodfirms.co/directory/platform/app-development/android"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-android-app-development-companies.webp"
                                    width="100px"
                                    alt="Top Mobile App Developers USA 2023"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Android App Development Companies</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed as Top Android App
                                    Development Companies by
                                    <br /> GoodFirms
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>

                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Company 2023"
                            className="div-c-box"
                            href="https://www.rightfirms.co/directory/mobile-app-development?sortby=&location_type=state&location=Florida"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-mobile-app-development-company-2023.webp"
                                    width="100px"
                                    alt="Top Mobile App Development Company 2023"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile App Development Company 2023
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed as the Top Mobile App
                                    Development Company in 2023 by Right Firms
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Companies in Florida"
                            className="div-c-box"
                            href="https://www.topdevelopers.co/directory/mobile-app-development-companies-in-usa"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/mobile-app-usa-2023.webp"
                                    width="100px"
                                    alt="Top Mobile App Developers USA 2023"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile App Developers{" "}
                                    <span
                                      style={{
                                        textTransform: "initial!important",
                                      }}
                                    >
                                      USA
                                    </span>{" "}
                                    2023
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed among the Top Mobile App
                                    Development Companies in the USA
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top SharePoint Consulting Companies for 2023"
                            className="div-c-box"
                            href="https://www.designrush.com/agency/it-services/microsoft-sharepoint"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-sharepoint-consulting-companies-for-2023.webp"
                                    width="100px"
                                    alt="Top 10 Blockchain Development Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top SharePoint Consulting Companies for 2023
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    {" "}
                                    Invohive is listed as the Top Microsoft
                                    SharePoint Consultants in 2023
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Companies in Florida"
                            className="div-c-box"
                            href="https://www.trustfirms.com/top-10-blockchain-development-companies-developers-in-usa-uae-india/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-50-blockchain-development-companies-hire-best-developers-in-usa.webp"
                                    width="100px"
                                    alt="Top 10 Blockchain Development Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top 10 Blockchain Development Companies
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is named Top Blockchain Development in
                                    USA 2022 by Trust Firms
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top 10 Blockchain Development Companies"
                            className="div-c-box"
                            href="https://www.designrush.com/agency/ar-vr"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-vr-ar-company-2023.webp"
                                    width="100px"
                                    alt="Top VR & AR Development Companies for 2023"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top{" "}
                                    <span
                                      style={{
                                        textTransform: "initial!important",
                                      }}
                                    >
                                      VR &amp; AR
                                    </span>{" "}
                                    Development Companies for 2023
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Designrush lists Invohive among the Top VR
                                    &amp; AR Development Companies
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Companies in Florida"
                            className="div-c-box"
                            href="https://www.topmobileappdevelopmentcompany.com/mobile-app-development-companies-in-florida/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-11-mobile-app-development-companies-in-florida 2022.webp"
                                    width="100px"
                                    alt="Top Mobile App Development Companies in Florida"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile App Development Companies in
                                    Florida
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed among the Top Mobile App
                                    Development Companies in Florida
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="DesignRush Named Invohive on Top Blockchain Development Companies"
                            className="div-c-box"
                            href="https://www.designrush.com/agency/software-development/blockchain?page=2"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-blockchain-development-companies-01.webp"
                                    width="100px"
                                    alt="Invohive Is Named Among the Top Blockchain Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Invohive Is Named Among the Top Blockchain
                                    Companies
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Designrush lists Invohive among the Top
                                    Blockchain Development Companies
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Blockchain Development Company"
                            className="div-c-box"
                            href="https://itrate.co/blockchain-developers/all"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-blockchain-development-company.webp"
                                    width="100px"
                                    alt="Top Blockchain Development Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Blockchain Development Company</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed as the Top Blockchain
                                    Development Company 2023 by ITRATE
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top 10+ Blockchain Technology Companies"
                            className="div-c-box"
                            href="https://www.goodfirms.co/directory/services/list-blockchain-technology-companies"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-10-blockchain-technology-companies.webp"
                                    width="100px"
                                    alt="Top 10+ Blockchain Technology Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top 10 Blockchain Development Companies
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    GoodFirms names Invohive among the top 10
                                    Blockchain Development Companies
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Web Design Company In the Dubai"
                            className="div-c-box"
                            href="https://selectedfirms.co/companies/web-design/dubai"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/web-design-companies-dubai.png"
                                    width="100px"
                                    alt="Top Web Design Companies in Dubai"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Web Design Companies in Dubai</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed among the Top Web Design
                                    Companies in Dubai
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Company"
                            className="div-c-box"
                            href="https://itrate.co/mob-app-developers/all"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-mobile-app-development-company.webp"
                                    width="100px"
                                    alt="Top 10 Mobile App Development Companies by IT Rate"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Mobile App Development Company</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed as the Top Mobile App
                                    Development Company 2023 by ITRATE
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Upcity.com names Invohive as a top mobile app company"
                            className="div-c-box"
                            href="https://upcity.com/mobile-app-development/washington-dc"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/cubix-a-leader-in-mobile-app-development1.jpg"
                                    width="100px"
                                    alt="Invohive Is Named Among the Top Mobile App Companies"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Invohive Is Named Among the Top Mobile App
                                    Companies
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is named a leader in the top mobile
                                    app development companies category by Upcity
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Invohive among top 8 mobile app companies"
                            className="div-c-box"
                            href="https://www.expertise.com/FL/west-palm-beach/software-development"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/best-software-development-flo.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3
                                    style={{
                                      textTransform: "initial!important",
                                    }}
                                  >
                                    Top 8 Software Development Companies
                                  </h3>
                                </div>
                                {/* /.fancy-box-header */}
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed among the top software
                                    development companies by Expertise
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Mobile App Development Companies"
                            className="div-c-box"
                            href="https://topappfirms.co/developers/app-development-companies/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-mobile-app-development-companies-worldwide.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Mobile App Development Companies</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    TopAppFirms listed Invohive among the top
                                    mobile app development companies
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top iOS App Development Companies in the US"
                            className="div-c-box"
                            href="https://topfirms.co/companies/ios-app-development/usa#:~:text=cubix%20is%20a%20partner/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-firms-mobile-app-development-company-badge.jpg"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3
                                    style={{
                                      textTransform: "initial!important",
                                    }}
                                  >
                                    Top iOS App Development Companies in the US
                                  </h3>
                                </div>
                                {/* /.fancy-box-header */}
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is among the top 30 iOS App developers
                                    in the USA 2022 by TopFirms
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Leading Software Company In 2022"
                            className="div-c-box"
                            href="https://topsoftwarecompanies.co/united-arab-emirates/software-development/agencies/dubai"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-software-developers-in-dubai.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Leading Software Company In 2022</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive Is Named Among the Top Software
                                    Companies by TopSoftwareCompanies.co
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top Blockchain Development Companies by ITFirms"
                            className="div-c-box"
                            href="https://www.itfirms.co/top-blockchain-development-companies/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/app-developer-itfirms-2021-blockchain.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Blockchain Development Companies by
                                    ITFirms
                                  </h3>
                                </div>
                                {/* /.fancy-box-header */}
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    ITFirms top-rates the Invohive dApp developers
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Wearable App Developers for 2022"
                            className="div-c-box"
                            href="https://www.designrush.com/agency/mobile-app-design-development?page=4"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/top-mobile-app developers-for-2023.webp"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Mobile App Developers for 2023</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is listed as the Top Mobile App
                                    Developers in 2023
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top mobile app development Company in California"
                            className="div-c-box"
                            href="https://www.goodfirms.co/directory/platform/app-development"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/california-goodfirms.jpg"
                                    width="100px"
                                    alt="GoodFirms Badge"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile App Development Companies in 2022
                                  </h3>
                                </div>
                                {/* /.fancy-box-header */}
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Top mobile app development company in
                                    California
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="op App Development Companies in 2022"
                            className="div-c-box"
                            href="https://appdevelopmentcompanies.co/local-agencies/us/app-developers/florida"
                            rel="nofollow"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/app-development-company-2021.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top App Development Companies in 2022</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is among the top mobile app
                                    development companies in Florida{" "}
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <a
                            title="Top Mobile App Development Company in 2022"
                            className="div-c-box"
                            href="https://www.topmobileappdevelopmentcompany.com/mobile-app-development-companies-in-saudi-arabia/"
                            rel="noopener"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/companies-in-saudi arabia.png"
                                    width="100px"
                                    alt=""
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile App Development Company in 2022
                                  </h3>
                                </div>
                                {/* /.fancy-box-header */}
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Top mobile app development company in Saudi
                                    Arabia
                                  </p>
                                </div>
                                {/* /.fancy-box-info */}
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Invohive Is Named Among the Top Mobile App Developers"
                            className="div-c-box"
                            href="https://www.topdevelopers.co/press-releases/leading-mobile-app-development-companies-march-2021"
                            rel="noopener"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/mobile-app-2021.png"
                                    width="100px"
                                    alt="Clutch Icon"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Invohive Is Named Among the Top Mobile App
                                    Developers
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is named the top mobile app
                                    development company in 2022
                                  </p>
                                </div>
                              </div>
                              {/* /.fancy-box-contents */}
                            </div>
                            {/* /.fancy-box */}
                          </a>
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/forbes-technologies-cource1.png"
                                  width="100px"
                                  alt="Clutch Icon"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>
                                  A Recognized Member of Forbes Technology
                                  Council
                                </h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is recognized as a member of the
                                  technology council by Forbes
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item  text-center px-3 pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top Hybrid App Developer in 2022"
                            className="div-c-box"
                            href="https://www.topdevelopers.co/directory/mobile-app-developers/hybrid"
                            rel="noopener"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/2020-hybrid-app-developers1.png"
                                    width="120px"
                                    alt="Invohive Awarded Forbes Technology Council Member"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>Top Hybrid App Developer in 2022</h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is named the top hybrid app developer
                                    in 2022 by TopDevelopers.co
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="carousel-item  text-center px-3 contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/mobile-appnew-2019.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Mobile App Developers By Clutch"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>Top Mobile Application Developers</h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive Is Named Among the Top Mobile App
                                  Developers by Clutch
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center pt-7 pt-sm-0-own contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/badge-goodfirms.png"
                                  width="120px"
                                  alt="Invohive Awarded Top Mobile App Developers By Clutch"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>Top Mobile App Developers</h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named among the top mobile app
                                  development companies in 2022 by GoodFirms
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/badge-mobile-app-online.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Mobile App Development Company By GoodFirms"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>
                                  Top E-commerce App Development Companies
                                </h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named among the best E-commerce app
                                  development companies in 2022 by Mobile App
                                  Daily
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center pt-7 pt-sm-0-own contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.Invohive.co/themes/responsiv-clean/assets/images/home/webp/badge-appfutura.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Mobile App Development Company By Mobile App Daily"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>Top App Development Companies</h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named the top app developer in
                                  Washington DC 2022 by App Futura
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/badge-design-rush.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Mobile App Development Company By AppFutura"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>
                                  Invohive Is Named Among the Top App Design
                                  Companies
                                </h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named the top app design company in
                                  2022
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center pt-7 pt-sm-0-own contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/badge-top-developers-co.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Mobile App Design Companies By DesignRush"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>
                                  Invohive Is Named as the Top Android Developers
                                </h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named among the top android app
                                  developers for 2022
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center contents-sm">
                          <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                            <div className="fancy-box-contents">
                              <figure>
                                <img
                                  src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/badge-business.png"
                                  width="100px"
                                  alt="Invohive Awarded Top Android App Development Companies By TopDevelopers"
                                />
                              </figure>
                              <div className="fancy-box-header">
                                <h3>Best App Maker and Development Company</h3>
                              </div>
                              {/* /.fancy-box-header */}
                              <div className="fancy-box-info">
                                <p className="mb-0">
                                  Invohive is named the top app maker and
                                  development solutions company of 2022
                                </p>
                              </div>
                              {/* /.fancy-box-info */}
                            </div>
                            {/* /.fancy-box-contents */}
                          </div>
                          {/* /.fancy-box */}
                        </div>
                        {/* /.carousel-item */}
                        <div className="carousel-item px-2 text-center pt-7 pt-sm-0-own contents-sm">
                          <a
                            title="Top rated app development companies in 2020"
                            className="div-c-box"
                            href="https://www.softwareworld.co/top-mobile-app-development-companies/"
                            rel="noopener"
                            target="_blank"
                          >
                            <div className="lqd-column-inner iconbox-shadow bg-white px-3 px-md-3 pt-40 pb-40">
                              <div className="fancy-box-contents">
                                <figure>
                                  <img
                                    src="https://www.cubix.co/themes/responsiv-clean/assets/images/home/webp/app_development_compay111.png"
                                    width="120px"
                                    alt="Invohive Awarded Top Hybrid App Development Company"
                                  />
                                </figure>
                                <div className="fancy-box-header">
                                  <h3>
                                    Top Mobile Application Developers by
                                    Software World
                                  </h3>
                                </div>
                                <div className="fancy-box-info">
                                  <p className="mb-0">
                                    Invohive is named the top app development
                                    company in 2022
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Carousel>
                    </div>
                    {/* /.carousel-items row */}
                  </div>
                  {/* /.carousel-container */}
                </div>
                {/* /.lqd-column col-md-12 */}
              </div>
              {/* /.row */}
              <div
                className="row pt-5 text-center-sm"
                id="awards-recognitions-footer"
              >
                <div className="col-lg-6">
                  <a
                    className="btn-underlined"
                    href="https://www.cubix.co/news"
                  >
                    See all our awards
                  </a>
                </div>
                <div
                  className="col-lg-6 text-right mt-sm-0-own margin-0-nav"
                  id="awards-recognitions-nav"
                />
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
        </section>
        <section className="vc_row" style={{ background: "#fff!important" }}>
          <hr
            className="hrstyleseofotter"
            style={{ width: "100%!important" }}
          />
        </section>
        <section
          id="contact"
          className="vc_row pt-100 pb-100"
          style={{ backgroundColor: "#1869f4" }}
        >
          <div
            className="liquid-row-overlay background-cover seobackgroundcvrfooter"
            style={{ backgroundColor: "#1869f4" }}
          />
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8">
                <div
                  className="footerformseo p-50 "
                  style={{ background: "#fff", borderRadius: 4 }}
                >
                  <h3 className="font-size-32 text-black mt-0">
                    Tell us about your project
                  </h3>
                  {/* <div id="seo-form-custom-footer" class="seo-form-custom-footer"></div> */}
                  <div className="form-wapper form-block fdgn2 seo-form-custom hs_custom_form">
                    <form
                      id="footer_form"
                      method="POST"
                      className="form_footer"
                      noValidate="novalidate"
                      data-hs-cf-bound="true"
                    >
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group required-control">
                            <label className="control-label" htmlFor="">
                              Name
                            </label>
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group required-control">
                            <label className="control-label" htmlFor="">
                              Email
                            </label>
                            <input
                              id="email"
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group required-control">
                            <label className="control-label" htmlFor="">
                              Phone
                            </label>
                            <input
                              id="phone"
                              name="phone"
                              type="text"
                              className="form-control"
                              placeholder="Phone"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group required-control">
                            <label className="control-label" htmlFor="">
                              Message
                            </label>
                            <textarea
                              id="message"
                              name="message"
                              className="form-control message_form_footer mb-md-2"
                              rows={3}
                              placeholder="Message"
                              required=""
                              defaultValue={""}
                            />
                            <span
                              id="error_"
                              className="error_txt error_txt_footer"
                              style={{ color: "red" }}
                            >
                              <span />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <button
                            type="submit"
                            id="submitservbottom"
                            className="btn btn-primary submitservtop"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fontschange" />
      </div>
    </div>
  );
}
