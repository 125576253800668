import React from "react";
import WebDevelopment from "./Pages/WebDevelopment";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import {
  Outlet,
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import MobileAppDevelopment from "./Pages/MobileAppDevelopment";
import Home from "./Pages/Home";
export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<p>Home</p>} /> */}
          <Route
            element={
              <div>
                <Navbar />
                <Outlet />
                {/* <Footer /> */}
              </div>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/web-development" element={<WebDevelopment />} />
            <Route
              path="/mobile-development"
              element={<MobileAppDevelopment />}
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
