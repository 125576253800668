import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

export default function Navbar() {
  function mobileNavToogle() {
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    document.querySelector("body").classList.toggle("mobile-nav-active");
    mobileNavShow.classList.toggle("d-none");
    mobileNavHide.classList.toggle("d-none");
  }
  const handleToggle = () => {
    mobileNavToogle();
  };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const menuItems = document.querySelectorAll(".a");
      console.log(menuItems);
      // Add event listener to each menu item
      document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
        console.log("el", el);
        el.addEventListener("click", function (event) {
          event.preventDefault();
          mobileNavToogle();
        });
      });
      menuItems.forEach((menuItem) => {
        menuItem.addEventListener("click", mobileNavToogle);
      });
    });
  }, []);
  return (
    <>
      {/* ======= Header ======= */}
      <section id="topbar" className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
              <a href="mailto:support@invohive.com">Support@invohive.com</a>
            </i>
            <i className="bi bi-phone d-flex align-items-center ms-4">
              <span>+6 1423 3733 50</span>
            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="#" className="twitter">
              <i className="bi bi-twitter" />
            </a>
            <a href="#" className="facebook">
              <i className="bi bi-facebook" />
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram" />
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin" />
            </a>
          </div>
        </div>
      </section>

      {/* <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <a class="navbar-brand" href="#">
            Logo
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  About
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      <header id="header" className="header d-flex align-items-center">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center z-index-1">
            <h1>
              Invohive<span>.</span>
            </h1>
          </Link>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="dropdown d-grid">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <span>Services</span>
                </a>
                <ul className="dropdown-menu bg-white">
                  <li>
                    <Link className="a" to="/web-development">
                      Web Development
                    </Link>
                  </li>

                  <li>
                    <Link className="a" to="/mobile-development">
                      Mobile Development
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>

              <li>
                <a href="/#contact">Contact Us</a>
              </li>
            </ul>
          </nav>
          <i
            role="button"
            onClick={handleToggle}
            className="mobile-nav-toggle mobile-nav-show bi bi-list"
          />
          <i
            role="button"
            onClick={handleToggle}
            className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
          />
        </div>
      </header>
    </>
    // <nav className=" py-4 position-absolute z-index-1 ">
    //   <div className="container md:px-44">
    //     <div className="row">
    //       <div className="flex items-center justify-between max-w-6xl ">
    //         {/* Logo */}
    //         <div className="text-white">
    //           <span className="text-3xl font-bold">Invohive.</span>
    //         </div>

    //         {/* Menus */}
    //         <div className="flex-grow text-white text-center">
    //           <ul className="flex justify-center space-x-4 mt-2">
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 Services
    //               </a>
    //             </li>
    //             <li class="nav-item dropdown">
    //               <a
    //                 className=" navlink dropdown-toggle text-white font-semibold hover:text-gray-300"
    //                 href="#"
    //                 id="navbarDropdown"
    //                 role="button"
    //                 data-bs-toggle="dropdown"
    //                 aria-expanded="false"
    //               >
    //                 Services
    //               </a>
    //               <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
    //                 <li>
    //                   <Link class="dropdown-item" to="/web-development">
    //                     Web Development
    //                   </Link>
    //                 </li>
    //                 <li>
    //                   <hr class="dropdown-divider" />
    //                 </li>
    //                 <li>
    //                   <Link class="dropdown-item" to="/mobile-development">
    //                     Mobile App Development
    //                   </Link>
    //                 </li>
    //                 <li>
    //                   <hr class="dropdown-divider" />
    //                 </li>
    //               </ul>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 Solutions
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 Work
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 About
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 Resources
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 Contact
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="#"
    //                 className="text-white font-semibold hover:text-gray-300"
    //               >
    //                 866-978-2220
    //               </a>
    //             </li>
    //           </ul>
    //         </div>

    //         {/* Button */}
    //         <div>
    //           <button className="bg-orange-400 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded uppercase">
    //             Estimate project
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </nav>
  );
}
